import styled from "styled-components";
import { responsive } from "../../../utils/style";
import GridColumn from "../../grid/GridColumn";
import GridContainer from "../../grid/GridContainer";
import GridRow from "../../grid/GridRow";

// Styled Elements
const Background = styled.div`
  background-color: var(--warm-20);
  border-bottom: 1px solid transparent;

  overflow: hidden;
  position: relative;
`;

const Spacer = styled.div`
  margin-top: 58px;
  padding-top: 0;

  ${responsive.md`
    margin-top: 70px;
  `}

  @media (min-width: 750px /* Grid S Tablet */) {
    padding-top: var(--spacing-3);
  }

  &.desktop {
    display: none;

    @media (min-width: 750px /* Grid S Tablet */) {
      display: block;
    }
  }

  &.mobile {
    display: block;

    @media (min-width: 750px /* Grid S Tablet */) {
      display: none;
    }
  }
`;

const ProductDetailPage = (props) => {
  return (
    <>
      <Background>
        <GridContainer>
          <Spacer className="mobile" />
          {/* Hero */}
          <GridRow>
            <GridColumn
              xs={{ size: 6 }}
              s={{ size: 12, hide: true }}
              m={{ size: 12, hide: true }}
            >
              {/* Mobile Hero */}
              <h1>UPDATED SINGLE SKU PDP</h1>
            </GridColumn>
            <GridColumn xs={{ size: 6 }} s={{ size: 5 }} m={{ size: 5 }}>
              <Spacer className="desktop" />
              <h1>UPDATED SINGLE SKU PDP</h1>

              {/* Product Info (Name, Subhead, etc.) */}

              {/* Flyout Links */}

              {/* Good Values */}
            </GridColumn>
          </GridRow>

          {/* Benefits Timeline */}
          <GridRow>
            <GridColumn xs={{ size: 12 }} s={{ size: 12 }} m={{ size: 12 }}>
              <p>Placeholder: Benefits Timeline</p>
            </GridColumn>
          </GridRow>

          {/* Ticker */}
          <GridRow xs={{ size: 12 }} s={{ size: 12 }} m={{ size: 12 }}>
            <GridColumn>
              <p>Placeholder: Ticker</p>
            </GridColumn>
          </GridRow>

          {/* Design Tour */}
          <GridRow xs={{ size: 12 }} s={{ size: 12 }} m={{ size: 12 }}>
            <GridColumn>
              <p>Placeholder: Design Tour</p>
            </GridColumn>
          </GridRow>

          {/* Ingredients */}
          <GridRow xs={{ size: 12 }} s={{ size: 12 }} m={{ size: 12 }}>
            <GridColumn>
              <p>Placeholder: Ingredients</p>
            </GridColumn>
          </GridRow>

          {/* FAQ */}
          <GridRow xs={{ size: 12 }} s={{ size: 12 }} m={{ size: 12 }}>
            <GridColumn>
              <p>Placeholder: FAQ</p>
            </GridColumn>
          </GridRow>

          {/* Articles */}
          <GridRow xs={{ size: 12 }} s={{ size: 12 }} m={{ size: 12 }}>
            <GridColumn>
              <p>Placeholder: Articles</p>
            </GridColumn>
          </GridRow>

          {/* Scientific Board */}
          <GridRow xs={{ size: 12 }} s={{ size: 12 }} m={{ size: 12 }}>
            <GridColumn>
              <p>Placeholder: Scientific Board</p>
            </GridColumn>
          </GridRow>

          {/* Press */}
          <GridRow xs={{ size: 12 }} s={{ size: 12 }} m={{ size: 12 }}>
            <GridColumn>
              <p>Placeholder: Press</p>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Background>
    </>
  );
};

export default ProductDetailPage;
